import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html, tableOfContents } = markdownRemark
  return (
    <Layout>
      <article className="w-full sm:w-1/2 md:w-3/4 max-w-3xl mx-auto px-5">
        <Helmet title={frontmatter.title} />
        <h1>{frontmatter.title}</h1>
        <p>
          <time className="text-xs" datetime={frontmatter.date}>
            {frontmatter.date}
          </time>
        </p>
        {frontmatter.toc ? (
          <>
            <h2>Table of contents</h2>
            <div dangerouslySetInnerHTML={{ __html: tableOfContents }}></div>
          </>
        ) : (
          <></>
        )}
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        path
        title
        toc
      }
      tableOfContents(
        absolute: true
        pathToSlugField: "frontmatter.path"
        maxDepth: 2
      )
    }
  }
`
